import React from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';

const onClick = (via) => {
  // let biodataLink = 'https://drive.google.com/file/d/1I7NPmh1bt0zw9qjGYI3Qg6g3iMP6Yoxy/view?usp=sharing';
  let photosLink = 'https://photos.app.goo.gl/Wup1EnJynzJxX8G7A';
  // let link = via.target.innerText === 'BioData'? biodataLink : photosLink;
  const newWindow = window.open(photosLink, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}
const onClickBio = (via) => {
  let biodataLink = 'https://drive.google.com/file/d/10sY4G10kLcRoMjHEtaUrpWWrtHIQVYhq/view?usp=sharing';
  // let photosLink = 'https://photos.app.goo.gl/Wup1EnJynzJxX8G7A';
  // let link = via.target.innerText === 'BioData'? biodataLink : photosLink;
  const newWindow = window.open(biodataLink, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}
const Home = () => {  
  return (
    <div name='home' className='w-full h-screen bg-[#000000] sm:pt-20'>
      {/* Container */}
      <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
        <p className='text-[#cae5ff]'><i>Hey, I am</i></p>
        <h1 className='text-4xl sm:text-7xl font-bold text-[#cae5ff]'>
          <i>Afsar</i>
        </h1>
        <h2 className='sm:text-4xl font-bold text-[#a1b7cc]'>
          Engineer | Entrepreneur | Chef | Musician
        </h2>
        <p className='text-[#8892b0] py-4 max-w-[700px] text-justify'>
          A practicing Muslim, and a <b>Software Engineer</b> by profession currently working with <b>SAP Labs India</b>, Whitefield, <b>Bengaluru</b> as a <b>Full-Stack Developer</b> specializing in designing & building exceptional digital experiences.
          </p>
      
      </div>
    </div>
  );
};

export default Home;
